var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      key: "user-editor-dialog-" + _vm.timestamp,
      attrs: { persistent: "", "max-width": "500px" },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      !!_vm.user
        ? _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "highlight", dark: "" } },
                [_c("v-toolbar-title", [_vm._v("Update User")])],
                1
              ),
              _c(
                "v-container",
                { attrs: { fluid: "", "grid-list-md": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("v-text-field", {
                            attrs: {
                              name: "Image Url",
                              label: "Image Url",
                              type: "text"
                            },
                            model: {
                              value: _vm.user.image,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "image", $$v)
                              },
                              expression: "user.image"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              name: "First Name",
                              label: "First Name",
                              type: "text"
                            },
                            model: {
                              value: _vm.user.firstname,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "firstname", $$v)
                              },
                              expression: "user.firstname"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              name: "Last Name",
                              label: "Last Name",
                              type: "text"
                            },
                            model: {
                              value: _vm.user.lastname,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "lastname", $$v)
                              },
                              expression: "user.lastname"
                            }
                          }),
                          _c("v-switch", {
                            attrs: { label: "Speaker" },
                            model: {
                              value: _vm.user.speaker,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "speaker", $$v)
                              },
                              expression: "user.speaker"
                            }
                          }),
                          _c("v-switch", {
                            attrs: { label: "Expo Tour" },
                            model: {
                              value: _vm.user.expotour,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "expotour", $$v)
                              },
                              expression: "user.expotour"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              items: _vm.roles,
                              label: "Access",
                              "item-text": "text",
                              "item-value": "value"
                            },
                            model: {
                              value: _vm.role,
                              callback: function($$v) {
                                _vm.role = $$v
                              },
                              expression: "role"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { flat: "", disabled: _vm.working },
                          on: { click: _vm.deleteUser }
                        },
                        [_vm._v("Delete")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "green",
                          attrs: {
                            flat: "",
                            disabled: _vm.working || !_vm.differs
                          },
                          on: { click: _vm.update }
                        },
                        [_vm._v("Update")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "", disabled: _vm.working },
                          on: { click: _vm.close }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }