<template>
  <v-dialog
    persistent
    v-model="open"
    max-width="500px"
    :key="`user-editor-dialog-${timestamp}`"
  >
    <v-card v-if="!!user">
      <v-toolbar color="highlight" dark>
        <v-toolbar-title>Update User</v-toolbar-title>
      </v-toolbar>
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex>
            <v-text-field
              v-model="user.image"
              name="Image Url"
              label="Image Url"
              type="text"
            />
            <v-text-field
              v-model="user.firstname"
              name="First Name"
              label="First Name"
              type="text"
            />
            <v-text-field
              v-model="user.lastname"
              name="Last Name"
              label="Last Name"
              type="text"
            />
            <v-switch v-model="user.speaker" label="Speaker" />
            <v-switch v-model="user.expotour" label="Expo Tour" />
            <v-select
              v-model="role"
              :items="roles"
              label="Access"
              item-text="text"
              item-value="value"
            />
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn flat :disabled="working" class="error" @click="deleteUser"
            >Delete</v-btn
          >
          <v-btn
            flat
            :disabled="working || !differs"
            class="green"
            @click="update"
            >Update</v-btn
          >
          <v-btn flat :disabled="working" class="secondary" @click="close"
            >Cancel</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { Role } from "@/helpers"
import firebase from "firebase/app"
import "firebase/database"

const USER_ACCESS_LEVELS_MAP = {}
USER_ACCESS_LEVELS_MAP[Role.Host] = "Host"
USER_ACCESS_LEVELS_MAP[Role.Super] = "Super Host"
USER_ACCESS_LEVELS_MAP[Role.Player] = "Player"
USER_ACCESS_LEVELS_MAP[Role.Audit] = "Auditor"
USER_ACCESS_LEVELS_MAP[Role.Spectator] = "Spectator"

const USER_ACCESS_LEVELS = Object.keys(USER_ACCESS_LEVELS_MAP).map(key => ({
  value: key,
  text: USER_ACCESS_LEVELS_MAP[key]
}))

export default {
  name: "UserEditor",
  data() {
    return {
      user: null,
      open: false,
      roles: USER_ACCESS_LEVELS,
      roleToSet: null,
      timestamp: Date.now(),
      working: false
    }
  },
  created() {
    console.log("user editor created")
    console.log(this.$store.getters)
  },
  watch: {
    userToUpdate(value) {
      if (value) {
        this.user = { ...value }
        this.roleToSet = null
        this.open = true
      }
    }
  },
  computed: {
    ...mapGetters("UserSettings", { userToUpdate: "user" }),
    differs() {
      return (
        JSON.stringify(this.user) !== JSON.stringify(this.userToUpdate) ||
        !!this.roleToSet
      )
    },
    role: {
      get() {
        if (!this.user) {
          return null
        } else if (this.user.super && this.user.role === Role.Host) {
          return Role.Super
        } else {
          return this.user.role
        }
      },
      set(value) {
        try {
          if (value === Role.Super) {
            const message = `${this.user.firstname} ${this.user.lastname} will be able to give root access to other users`
            if (!confirm(message)) throw new Error("Aborted")
            this.roleToSet = Role.Super
            this.user.role = Role.Host
          } else {
            this.roleToSet = value
            this.user.role = value
          }
        } catch (e) {
          alert(e.message)
          console.warn(e)
          this.close()
        }
      }
    }
  },
  methods: {
    ...mapActions("UserSettings", ["updateUser"]),
    async update() {
      try {
        const { role: previousUserRole } = this.user
        this.working = true
        if (!this.user.id) throw new Error("No user ID")

        const obj = {
          image: this.user.image ? this.user.image : null,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          speaker: !!this.user.speaker,
          expotour: !!this.user.expotour
        }

        if (this.roleToSet === Role.Super) {
          obj.super = true
          obj.role = Role.Host
          const update = {}
          update[`/0/${this.user.id}`] = true
          update[`/1/${this.user.id}`] = null
          await firebase
            .database()
            .ref("access")
            .update(update)
        } else if (this.roleToSet) {
          obj.super = null
          if (this.roleToSet === Role.Host) {
            obj.role = Role.Host
            const update = {}
            update[`/0/${this.user.id}`] = null
            update[`/1/${this.user.id}`] = true
            await firebase
              .database()
              .ref("access")
              .update(update)
          } else {
            if (this.roleToSet === Role.Audit) {
              obj.role = Role.Audit
            } else if (this.roleToSet === Role.Spectator) {
              obj.role = Role.Spectator
            } else {
              obj.role = Role.Player
            }
            if ([Role.Super, Role.Host].includes(previousUserRole)) {
              const update = {}
              update[`/0/${this.user.id}`] = null
              update[`/1/${this.user.id}`] = null
              await firebase
                .database()
                .ref("access")
                .update(update)
            }
          }
        }
        await this.$store.dispatch("updateUser", { userID: this.user.id, obj })
      } catch (e) {
        console.error(e)
        alert(e.message)
      } finally {
        this.working = false
        this.close()
      }
    },
    async deleteUser() {
      try {
        this.working = true
        if (!this.user.id) throw new Error("No user ID")
        await this.$store.dispatch("updateUser", {
          userID: this.user.id,
          obj: { status: "offline", clientID: null, gameID: null }
        })
      } catch (e) {
        console.error(e)
        alert(e.message)
      } finally {
        this.working = false
        this.close()
      }
    },
    close() {
      this.open = false
      this.roleToSet = null
      this.user = null
      this.timestamp = Date.now()
      this.updateUser({ user: null })
    }
  }
}
</script>
